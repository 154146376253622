import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Container } from "semantic-ui-react";
import AdContent from './AdContent';
import StaticMap from './StaticMap';
import { useQuery } from '@apollo/client';
import SubjectContext from './SubjectContext';
import AutoContent from './AutoContent';
import RecordSearch from './RecordSearch';
import Records from './Records';

const WEATHER_DATA = gql`
  query WeatherData($placeId: String!) {
      observation(criteria: {subjectId: $placeId, title: "Weather Forecast"}) {
          observations {
              id
              modified
              title
              category
              type
              start
              end
              contentType
              content
          } 
      }
      placeById(id: $placeId) {
        id
        label
      }   
  }`
  ;

const WEATHER_SUMMARY = gql`
  query WeatherData($placeId: String!) {
      observation(criteria: {subjectId: $placeId, sensor: "com.navscout.summary.WeatherSummary"}) {
          observations {
              id
              modified
              title
              category
              type
              start
              end
              contentType
              content
          } 
      }
  }`
  ;

const PlaceWeather = props => {
    const params = useParams();
    const [summaries, setSummaries] = useState([])
    const [data, setData] = useState(null)
    const [warnings, setWarnings] = useState([])

    useQuery(
        WEATHER_DATA, 
        {client: props.client, 
            variables: {label: props.label, placeId: params.id},
            skip: !params.id,
            onCompleted: d => setData(d)
        });

    useQuery(
        WEATHER_SUMMARY, 
        {client: props.client, 
            variables: {label: props.label, placeId: params.id},
            skip: !params.id,
            onCompleted: d => {
                var s = [...d.observation.observations]
                s.sort((a,b) => a.start.localeCompare(b.start))
                setSummaries(s)
            }
        });

    const obs = data?.observation?.observations[0]
    document.title = `navscout - ${data?.placeById?.label}`;
    return <Container>
        <Breadcrumb>
            <Breadcrumb.Section link><Link to='/place'>Places</Link></Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link><Link to={`/place/${params.id}`}>{data?.placeById?.label || props.label}</Link></Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Weather</Breadcrumb.Section>
        </Breadcrumb>
        { data?.placeById?.location ? <StaticMap location={{point: data.placeById.location}} /> : null }
        <br/>
        <AdContent id='ad' />
        <AutoContent content={obs?.content} contentType={obs?.contentType} />
        <RecordSearch 
            filters={{type:"WARNING", tags: ["Weather"], subjectId: params.id, occurring: "PT-2H/PT36H"}} 
            fetchContent={true}
            client={props.client}
            hidden={true} 
            page={1} pageSize={10}
            onUpdate={(o, d) => {
              setWarnings(o.observations); 
            }} 
            />
        <Records records={warnings} collapsed={true} title="Warnings" client={props.client} account={props.account} /> 
        {summaries.map((s, i) => (
            <div key={i}>
                <h2>{s.title}</h2>
                <AutoContent content={s?.content} contentType={s?.contentType} />
            </div>
        ) )}
        <br/>
    </Container>
}

export default PlaceWeather;