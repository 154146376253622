import React, { useState } from 'react';
import PlaceMap from "./PlaceMap";
import _ from 'lodash';
import gql from 'graphql-tag';
import Util from './Util';
import { Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';

const ADD_PLACE = gql`
mutation AddPlace($label:String) {
  addPlace(place: {label:$label,location:{label:$label},listed:true}) {
    id
    label
    location {
      longitude
      latitude
    }
  }
}`;

const Places = props => {
  const searchParams = new URLSearchParams(window.location.search);
  const subjectId = searchParams.get('subjectId');

  const navigate = useNavigate();
  let [searchPlace, setSearchPlace] = useState({id: subjectId})
  let [message, setMessage] = useState(null)

  document.title = 'navscout - Places ';
  return <div className='h100'>
      <Modal
        onClose={(a) => {
          if (a.target.value === 'register') {
            navigate('/register')
          };
          setMessage(null)
        }}
        open={message !== null}
        content={message}
        header='Notice'
        actions={['Cancel', {key: 'register', content: 'Register', positive: true, value: 'register'}]}
        dimmer='blurring'
        />
      <PlaceMap 
        client={props.client} 
        searchValue={searchPlace} 
        onSearchChange={l => {
          if (l === null) {
            setSearchPlace(null)
            return
          }
          searchForPlace(props.client, l, props.account, setSearchPlace, setMessage)
        }}
           />
    </div>
}

const searchForPlace = (client, place, account, setSearchPlace, setMessage) => {
  if (Util.isBlank(place?.label)) {
    setSearchPlace(null)
    return
  }
  if (place.source === 'navscout') {
    console.log(place.label, 'is available')
    setSearchPlace(place)
  } else {
    if (account == null) {
      setMessage('Sign up for an account to view ' + place?.label)
    } else {
      console.log('add', place)
      addPlace(client, {label: place.label}, setSearchPlace)
    }
  }
}

const addPlace = (client, values, setSearchPlace) => {
  console.log('Add place ', values);
  client.mutate({
    mutation: ADD_PLACE,
    variables: values,
    refetchQueries: ['Places', 'Resources']
  }).then(data => {
    if (data.data.addPlace === null) {
      console.log('Add place failed');
    } else {
      console.log('Added place', data.data.addPlace)
      setSearchPlace(data.data.addPlace)
    }
  });
}

export default Places;