import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Divider, Form, Message } from 'semantic-ui-react';
import Hideable from './Hideable';
import Validate from './Validate';

const LOGIN = gql`
mutation Login($email:String!, $password:String!) {
  loginWithPassword(email:$email, password:$password)
}`
;

const Login = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  document.title = `navscout - Login`;

  return <Container>
      <h3>Enter your email and password</h3>
      <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          login(props.client, {email, password}, navigate, setError);
        }} >
        <Form.Field>
          <label>Email</label>
          <input name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input type='password' name='password' placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />
        </Form.Field>
        <Divider hidden />
        <Container textAlign='center'>
          <Button
              disabled={!Validate.notBlank(email, password)}
              color='blue'
              style={{margin:'0 auto 0 auto'}}
              >Login</Button>
          <Divider hidden />
          <Hideable hidden={!error}>
            <Message content={error} />
          </Hideable>
          <Link to='/login/get-token'>Forgot Password</Link>
          &nbsp;&bull;&nbsp;
          <Link to='/register'>Register</Link>
        </Container>
      </Form>
    </Container>;
}

const login = (client, values, navigate, setError) => {
  client.mutate({
    mutation: LOGIN,
    variables: values,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.loginWithPassword === null) {
      setError('Login failed');
    } else {
      navigate('/');
    }
  }).catch(error => setError(error.message));
}

export default Login;