import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Divider, Form, Input } from 'semantic-ui-react';
import Validate from './Validate';

const LOGIN_TOKEN = gql`
mutation Login($email:String!, $token:String!) {
  loginWithToken(email:$email, token:$token)
}`
;

const LoginToken = props => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  let navigate = useNavigate();

  document.title = `navscout - Login`;

  return <Container>
      <h3>Enter your email and token that was sent to you</h3>
      <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          login(props.client, {email, token}, navigate);
        }} >
        <Form.Field>
          <label>Email</label>
          <Input name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <Input type='token' name='token' placeholder='Token' value={token} onChange={e => setToken(e.target.value)} />
        </Form.Field>
        <Divider hidden />
        <Container textAlign='center'>
          <Button
              disabled={!Validate.notBlank(email, token)}
              color='blue' 
              style={{margin:'0 auto 0 auto'}}
              primary="true"
              >Login</Button>
          <Divider hidden />
          <Link to='/login'>Login</Link>
          &nbsp;&bull;&nbsp;
          <Link to='/login/get-token'>Register</Link>
        </Container>
      </Form>
    </Container>;
}

const login = (client, values, navigate) => {
  client.mutate({
    mutation: LOGIN_TOKEN,
    variables: values,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.loginWithToken === null) {
      console.log('Login failed');
    } else {
      navigate('/');
    }
  });
}

export default LoginToken;